// store/modules/dashboardDrawer.js
const state = {
  dashboardDrawer: true,
}

const mutations = {
  toggleDashboardDrawer(state) {
    state.dashboardDrawer = !state.dashboardDrawer
  },
}

const actions = {
  toggleDashboardDrawer({ commit }) {
    commit("toggleDashboardDrawer")
  },
}

const getters = {
  isDashboardDrawerOpen(state) {
    return state.dashboardDrawer
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
